import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compact } from 'lodash'

import { useEventListeners } from '@deathbyjer/react-event-manager'

import { distanceFromTop, scrollTopTo } from 'lib/utilities/dom'

import TopBar from './main_top_bar'

import Page from '../page'
import SigningArea from '../signature/signing_area'
import SignatureModals from '../signature/modals'

import { StartSigningButton, NextSignatureButton } from '../signature'

import { setSigningAs } from '../signature/store'

function useContainerWidth(container_id) {
  const [width, setWidth] = useState(0)

  const tracker = useCallback(() => {
    const el = document.getElementById(container_id)
    const rect = el.getBoundingClientRect()
    setWidth(rect.width)
  }, [container_id])

  useEffect(() => {
    tracker()
    window.addEventListener("resize", tracker)
    return () => window.removeEventListener("resize", tracker)
  }, [tracker])

  return width
}

function scrollToPage(page_id, parentRef, { scrollsWindow = false } = {}) {
  const parent = parentRef.current

  if (!parent)
    return

  const cls = `page-${page_id}`
  const el = parent.getElementsByClassName(cls)[0]

  const top = distanceFromTop(el) - distanceFromTop(parent)

  scrollsWindow ? scrollTopTo(parent, { top }) : parent.scrollTo({ behavior: 'smooth', top })
}

export default React.forwardRef(({ form_id, package_id, scrollsWindow, viewOnly, signing, canSign }, ref) => {
  const allPagesRef = useRef()
  const fullState = useSelector(state => state)
  const signingSignature = useSelector(state => state.form_signing_area.signature_for_signing) && !viewOnly
  const required = useSelector(state => state.form_signatures.signatures[signingSignature]?.required)
  const page_ids = useSelector(state => state.form_pages.ordered_pages)
  const container_id = `main-form-${form_id}`
  const dispatch = useDispatch()
  const currentUser = useSelector(({ form_signatures: state }) => state.current_user)

  useEventListeners({
    [`form:${form_id}:scrollToPage`]: (page_id) => {
      scrollToPage(page_id, allPagesRef, {scrollsWindow})
    }
  })

  useEffect(() => {
    // opens signing mode on load
    if (signing) dispatch(setSigningAs(currentUser))
  }, [])

  const top = ref.current ? distanceFromTop(ref.current) : 0

  const classes = compact([
    "main-form-area",
    viewOnly ? 'view-only' : null
  ])

  return <div className={classes.join(" ")} ref={ref} id={container_id}>
    {viewOnly ? null : <TopBar container_id={container_id} top={top} /> }
    <div className="all-pages" ref={allPagesRef}>
      { viewOnly || !canSign ? null : <StartSigningButton>Start Signing</StartSigningButton> }
      { viewOnly || !canSign ? null : <NextSignatureButton>Next Signature</NextSignatureButton> }
      { page_ids.map(page_id => <Page id={page_id} package_id={package_id} key={page_id} frozen={viewOnly} locked={viewOnly} />)}
      {signingSignature && canSign ? <SigningArea signatureId={signingSignature} required={required} /> : null }
      <SignatureModals />
    </div>
  </div>
})
