import React, { useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import { ajax } from 'jquery'

import md5 from 'blueimp-md5'
import { pick, uniqBy } from 'lodash'

//import Modal from './modal'
import Modal from 'react-bootstrap/Modal';

import { addCSRF } from 'lib/utilities'
import { setSignature } from '../store'
import SignatureImage from 'components/signatures/image'
import { useEventManager } from '@deathbyjer/react-event-manager'

import { Signature as NewSignature,
         Initial as NewInitial } from 'components/signatures/edit'


const initialState = {
  user_signature: null,
  user_initials: null,
  stored_signatures: []
}

function filterSignature(signature) {
  if (!signature)
    return null

  if (signature.image)
    return { image: signature.image }

  return pick(signature, ['fontFamily', 'text'])
}

const Store = createSlice({
  name: "signing_area",
  initialState,
  reducers: {
    setStoredSignatures(state, { payload: stored_signatures, user_signature, user_initials}) {
      state.stored_signatures = stored_signatures
      state.user_signature = user_signature
      state.user_initials = user_initials
    },

    load(state, { payload: { user_signature, user_initials }}) {
      state.user_signature = filterSignature(user_signature)
      state.user_initials = filterSignature(user_initials)
    },

    setUserSignature(state, { payload: signature}) {
      state.user_signature = signature
    },

    setUserInitials(state, { payload: initials}) {
      state.user_initials = initials
    },

    setSignatureForSigning(state, { payload: signature_id }) {
      state.signature_for_signing = signature_id
    },
  }
})

export const reducer = Store.reducer
export const { load, setSignatureForSigning, setUserSignature } = Store.actions

function Tab({children, active, onClick}) {
  const classes = ['tab']
  if (active)
    classes.push('active')

  return <div className={classes.join(" ")} onClick={onClick}>
    {children}
  </div>
}

function prepareSignature(tab, ref) {
  const signature = ref.getSignature()

  if (signature == null)
    return null

  switch(tab) {
  case 'draw':
    return { image: signature }
  case 'type':
    return pick(signature, ['fontFamily', 'text'])
  }
}

function saveSignature({instance_id, user_id, signature_id, signature, asDefault}) {
  const url = `/forms/v3/instance/${instance_id}/signature`
  const data = addCSRF({ signature: { signature_id, signature, user_id, save: asDefault } })

  return new Promise((res, rej) => {
    const success = ({ signature, all_signatures_signed }) => res([signature, all_signatures_signed])

    const error = xhr => {
      try {
        const err = JSON.parse(xhr.responseText)
        rej(err.error)
      } catch(e) {
        rej("There was a problem accessing the server")
      }
    }
    ajax({ url, data, type: 'post', success, error})
  })
}

function removeDefaultSignature({instance_id, initials}) {
  const url = `/forms/v3/instance/${instance_id}/saved_signature`
  const data = addCSRF({ initials })

  return new Promise((res, rej) => {
    const success = () => res()

    const error = xhr => {
      try {
        const err = JSON.parse(xhr.responseText)
        rej(err.error)
      } catch(e) {
        rej("There was a problem accessing the server")
      }
    }
    ajax({ url, data, type: 'delete', success, error})
  })
}

const EMPTY_FUNCTION = () => {}

function SignModal(props) {
  const signature = useSelector(({form_signing_area: state}) => state.user_signature)
  if (signature)
    return <SavedModal signature={signature} {...props} />

  return <NewSignature showDefault={true} {...props} />
}


function InitialModal(props) {
  const signature = useSelector(({form_signing_area: state}) => state.user_initials)
  if (signature)
    return <SavedModal signature={signature} initials={true} {...props} />

  return <NewInitial showDefault={true} {...props} />
}

function SavedModal({onSign, closeModal, signature, initials}) {
  const dispatch = useDispatch()
  const instance_id = useSelector(({global: state}) => state.instance_id)
  const handleSign = useCallback(() => onSign(signature, { initials }), [signature])

  const removeDefault = useCallback(() => {
    removeDefaultSignature({instance_id, initials})
      .then(() => {
        const action = initials ? "setUserInitials" : "setUserSignature"
        dispatch(Store.actions[action](null))
      })
  }, [dispatch, initials, instance_id])


  return <>
      <div className="saved-signature">
        <SignatureImage signature={signature} />
      </div>

      <div className="use-saved-signature">
        <div>Click Sign below to sign using your default {initials ? "initials" : "signature"}.</div>
      </div>

      <div className="buttons">
        <button className="btn" onClick={closeModal}>Close</button>
        <div className="save">
          <button className="btn btn-primary" onClick={handleSign}>Sign</button>
          <div className="danger link" onClick={removeDefault}>Remove Default</div>
        </div>
      </div>
  </>
}

function displayErrors(err) {
  if (Array.isArray(err))
    return err.map((e,i) => <div key={i}>{e}</div>)

  if (typeof(err) == "object")
    return Object.entries(err).map(([k, e]) => <div key={k} className={`${k}-error`}>{e}</div>)

  return err
}

function useSignAndClose({instance_id, signature_id, user_id, closeModal, setError, required}) {
  const events = useEventManager()
  const dispatch = useDispatch()

  const updateDefault = useCallback(({signature, initials}) => {
    const action = initials ? "setUserInitials" : "setUserSignature"
    dispatch(Store.actions[action](signature))
  }, [dispatch] )

  const finishUpdate = useCallback(({ all_signatures_signed }) => {
    closeModal()

    if (all_signatures_signed)
      events.applyEventListeners('allSignaturesSigned')

  }, [closeModal, events])

  const postSave = useCallback(({ signature, asDefault, initials, all_signatures_signed }) => {
    dispatch(setSignature({ signature_id, signature }))
    asDefault ? updateDefault({ signature, initials }) : null
    events.applyEventListeners('signatureAdded', {signature_id, required})
    finishUpdate({ all_signatures_signed })
  }, [dispatch, signature_id])

  return useCallback((signature, { asDefault, initials }) => {
    saveSignature({instance_id, signature_id, signature, user_id, asDefault})
      // .then(signature => postSave({signature, asDefault, initials}))
      .then(([signature, all_signatures_signed]) => postSave({ signature, asDefault, initials, all_signatures_signed }))
    .catch(err => setError(err))
  }, [dispatch, instance_id, signature_id, user_id, events])
}

function SigningModal({onExited}) {
  const [show, setShow] = useState(true)
  const [error, setError] = useState(null)
  const instance_id = useSelector(({global: state}) => state.instance_id)
  const signature_id = useSelector(({form_signing_area: state}) => state.signature_for_signing)
  const signature_type = useSelector(({ form_signatures: state }) => state.signatures[signature_id].type)
  const fullState = useSelector(state => state)
  const required = useSelector(state => state.form_signatures.signatures[signature_id].required)
  const user_id = useSelector(({global: state}) => state.user.id)
  const isInitials = signature_type == "initials"

  const closeModal = useCallback(() => setShow(false), [setShow])
  const signAndClose = useSignAndClose({instance_id, signature_id, user_id, closeModal, setError, required})
  const Component = isInitials ? InitialModal : SignModal


  return <Modal className="packages-form-component-modal" show={show} onExited={onExited} centered={true} size={isInitials ? "sm" : "md"}>
    <Modal.Body className="signing-area">
      { error ? <div className="error">{displayErrors(error)}</div> : null }
      <Component onSign={signAndClose}  closeModal={closeModal}/>
    </Modal.Body>
  </Modal>
}

export default function({onClose, required}) {
  const dispatch = useDispatch()
  const show = useSelector(({form_signing_area: state}) => state.signature_for_signing ? true : false)
  const hide = useCallback(() => {
    dispatch(setSignatureForSigning(null))
    onClose ? onClose() : null
  }, [dispatch])

  return show ? <SigningModal onExited={hide}></SigningModal> : null
}
