import { useCallback, useMemo, useRef, useState, useEffect } from "react";

export function useWatchableRef(initialValue = null) {
  const ref = useRef(initialValue);
  const [version, setRefVersion] = useState(null);

  const handleRef = useCallback((node) => {
    ref.current = node;
    setRefVersion(Math.random());
  }, []);

  return useMemo(
    () => ({
      ref: handleRef,
      current: ref.current,
      version,
    }),
    [handleRef, version]
  );
}
