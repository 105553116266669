import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit'
import { cloneDeep, findIndex } from 'lodash'

import { setActiveComponent, uniqueComponentId } from '../page'

import { arrayContains } from 'lib/utilities'
import { addIdsToValues, sortByPosition, itemAtPath, appendToKey } from '../utilities'
import { daysInYear } from 'date-fns'

const POSITION_TYPES = ["signature", "time", "name"]
const generateDefaultPositions = () => ({ signature: [], time: [], name: [] })
const generateSignaturesToSign = () =>({list: [], lookups: {}, pages: {}, signatures: {}, signature_groups: {}, allSigned: false})

function addSignatureToGroups(groups, {id, deal_party}) {
  if (!deal_party.group)
    return

  const group = groups[deal_party.group]
  appendToKey(group, "signatures", id)
  appendToKey(group, "roles", deal_party.as)
}

function appendPagePositionLists(lists, { id, positions }) {
  if (!positions)
    return

  let page
  for (let type of POSITION_TYPES) {
    if (!positions[type])
      continue

    page = positions[type].page
    if (!lists[page])
      lists[page] = generateDefaultPositions()

    lists[page][type].push(id)
  }
}

function appendGroupPositions(list, { id, positions }) {
  if (positions.length == 0)
    return

  appendToKey(list, positions[0].page, id)
}

function sortPageLists(lists, lookup = null, path = null) {
  const pageLists = Object.entries(lists)
  const sortedLists = pageLists.map(([k,v]) => [k, sortAttributeLists(v, lookup, path)])
  return Object.fromEntries(sortedLists)
}

function sortAttributeLists(lists, lookup = null, path = null) {
  const sortWithLookup = (a,b) => {
    const itemA = lookup ? lookup[a] : a
    const itemB = lookup ? lookup[b] : b

    const posA = path ? itemAtPath(itemA, path) : itemA
    const posB = path ? itemAtPath(itemB, path) : itemB

    return sortByPosition(posA, posB)
  }

  const sortedLists = Object.entries(lists).map(([k,v]) => [k, v.sort(sortWithLookup)])
  return Object.fromEntries(sortedLists)
}

function signaturesToSign(signing_as, {signature_groups, signatures, signed_signatures, role_user_counts}) {
  const items = generateSignaturesToSign()
  let isSigned, signable

  // First, add signatures
  for (let signature of Object.values(signatures)) {
    if (signature.deal_party.id && signature.deal_party.id == signing_as.party_id) {
      isSigned = signed_signatures[signature.id]
      signable = {
        id: `s-${signature.id}`, 
        signature, 
        position: signature.positions.signature, 
        isSigned,
        required: signature.required
      }
      
      items.signatures[signature.id] = isSigned ? "signed" : "unsigned"

      appendToKey(items.pages, signature.positions.signature.page, single)
      items.list.push(signable)
    }
  }

  // Now, add the signature groups
  const signatureIsSignedByMe = id => signed_signatures[id] && signed_signatures[id].user_id == signing_as.user_id
  for (let signature_group of Object.values(signature_groups)) {
    if (arrayContains(signature_group?.roles, signing_as?.roles)) {
      isSigned = signature_group.signatures.some(signatureIsSignedByMe)
      items.signature_groups[signature_group.id] = isSigned ? "signed" : "unsigned"

      signable = {
        id: `sg-${signature_group.id}`,
        signature_group,
        position: signature_group.positions[0],
        isSigned
      }

      const signature_count = signature_group.signatures.length
      const user_count = role_user_counts[signature_group.role] || 0
      const min_required = Math.min( signature_count, signature_group.min_required || user_count )
      
      if (min_required >= user_count)
        signable.required = "required"
      else if (isSigned >= min_required)
        signable.required = "optional"
      else if (min_required == signature_count)
        signable.required = "all"
      else
        signable.required = "limited"

      signable.min_required = min_required
      items.list.push(signable)

      for (let position of signature_group.positions)
        appendToKey(items.pages, position.page, { id: `sg-${signature_group.id}`, signature_group, position, isSigned})
    }
  }

  for (signable of items.list)
    items.lookups[signable.id] = signable

  items.list = items.list.sort((a,b) => sortByPosition(a.position, b.position))
  items.allSigned = items.list.every(sig => sig.isSigned)
  items.signed = items.list.filter(sig => sig.isSigned)
  items.left = items.list.filter(sig => !sig.isSigned)
  return items
}

const initialState = {
  roles: {},
  signature_groups: {},
  signed_signatures: {},
  signatures: {},
  signers: {},
  by_page: {},
  signed_pages: {},
  groups_by_page: {},
  role_user_counts: {},

  user_signatures: generateSignaturesToSign(),
  signatures_to_sign: generateSignaturesToSign(),
  current_signature: null,
  current_user: {},
  signing_as: null,

  focused_signature: null
}

const Store = createSlice({
  name: "signatures",
  initialState,
  reducers: {
    setSignatures(state, { payload: {roles, signatures, signed_signatures, signers, signature_groups, user, user_deal_party, user_roles, role_user_counts} }) {
      state.roles = roles
      state.signatures = addIdsToValues(signatures)
      state.signed_signatures = addIdsToValues(signed_signatures)
      state.signers = { ...signers, [user.id]: user }
      state.role_user_counts = role_user_counts

      // Expand the Signature Groups
      signature_groups = cloneDeep(signature_groups)
      for (let id in state.signatures)
        addSignatureToGroups(signature_groups, state.signatures[id])
      state.signature_groups = addIdsToValues(signature_groups)

      // Separate the signatures by page
      const page_positions = {}
      for (let id in state.signatures)
        appendPagePositionLists(page_positions, state.signatures[id])

      state.by_page = sortPageLists(page_positions, state.signatures, "positions.signature")

      // Separate the SignatureGroups by page
      const group_page_positions = {}
      for (let id in state.signature_groups)
        appendGroupPositions(group_page_positions, state.signature_groups[id])

      state.groups_by_page = sortAttributeLists(group_page_positions, state.signature_groups, "positions[0]")

      state.current_user = {
        user_id: user.id,
        party_id: user_deal_party,
        roles: user_roles
      }

      state.user_signatures = signaturesToSign(state.current_user, state)
    },

    setSigningAs(state, { payload: {user_id, party_id, roles}}) {
      state.signing_as = { user_id, party_id, roles }
      const signatures = state.signatures_to_sign = signaturesToSign(state.signing_as, state)
      state.current_signature = signatures.allSigned ? 0 : findIndex(signatures.list, sig => !sig.isSigned)
    },

    setCurrentSignature(state, { payload: current }) {
      state.current_signature = current
    },

    clearSigningAs(state) {
      state.signing_as = null
      state.signatures_to_sign = generateSignaturesToSign()
    },

    setSignature(state, { payload: { signature_id, signature }}) {
      if (!state.signatures[signature_id])
        return

      const updated_signature = state.signatures[signature_id]
      const page = updated_signature.positions.signature.page

      state.signed_signatures[signature_id] = signature
      state.signed_pages[page] = true
      state.signatures_to_sign = signaturesToSign(state.signing_as, state)
      state.user_signatures = signaturesToSign(state.current_user, state)
    },

    removeSignature(state, { payload: signatureId }) {
      if (!state.signatures[signatureId])
        return

      const page = state.signatures[signatureId].positions.signature.page

      delete state.signed_signatures[signatureId]
      state.signed_pages[page] = state.by_page[page].signature.some(id => state.signed_signatures[id] ? true : false)
      state.signatures_to_sign = signaturesToSign(state.signing_as, state)
      state.user_signatures = signaturesToSign(state.current_user, state)
    },

    focusSignature(state, { payload: signature_id }) {
      state.focused_signature = signature_id
    },

    unfocusSignature(state, { payload: signature_id }) {
      if (state.focused_signature && state.focused_signature == signature_id) {
        state.focused_signature = null
      }
    }
  }
})

export const {
  setSignatures,
  setCurrentSignature,
  setSigningAs,
  clearSigningAs,
  setSignature,
  removeSignature,
  focusSignature,
  unfocusSignature } = Store.actions

// The following middleware monitors focus on signatures to signify that they are an active component for the DrawArea
const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: focusSignature,
  effect: ({payload: id}, { dispatch }) => {
    const key = uniqueComponentId('signature', id)
    dispatch(setActiveComponent(key))
  }
})

listenerMiddleware.startListening({
  actionCreator: unfocusSignature,
  effect: ({payload: id}, { dispatch, getState}) => {
    const state = getState()
    const key = uniqueComponentId('signature', id)

    if (key == state.activeComponent)
      dispatch(setActiveComponent(null))
  }
})

export const { middleware } = listenerMiddleware

export default Store.reducer
