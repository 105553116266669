import React from 'react'
import PropTypes from 'prop-types'
import {compact} from 'lodash'
import ApplyButton from 'components/buildings/visible_templates/apply_button'

import Fees from './fees'
import PdfView from './pdf_view'
import Requirement from './requirement'

function errorsFromXhr(xhr) {
  try { return JSON.parse(xhr.responseText) }
  catch(e) {}

  return {error: xhr.responeText}
}

function decodeEntities(str) {
  // this prevents any overhead from creating the object each time
  var element = document.createElement('div');

  if(str && typeof str === 'string') {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = '';
  }

  return str;
}


const containerClassName = "packages-preview-component"

class Preview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      thePackage: null, // means just package, because the package keyword is reserved
      loading: true,
      pdfSectionToLoad: null, // bookmark name
      activeTab: "requirements"
    }

    this.handleToggleTab = this.handleToggleTab.bind(this)
    this.jumpToPage = this.jumpToPage.bind(this)
  }

  componentDidMount() {
    this.listenModalClose()
    this.open()
    this.getPdf()
  }

  componentDidUpdate(_, prevState) {
    const { thePackage, bookmarkData, mappedPages } = this.state

    if (!prevState.mappedPages && mappedPages)
      return this.setLoading(false)

    if ((!prevState.bookmarkData || !prevState.thePackage) && thePackage && bookmarkData)
      this.mapSectionsWithPage()
  }

  componentWillUnmount() {
    $(`.${containerClassName}`).modal("hide")
  }

  fees() {
    return this.props.fees || []
  }

  loadPdf() {
    const url = `/packages/${this.props.hashedId}/get_or_generate_template_preview`

    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        success: data => resolve(data),
        error: xhr => reject(errorsFromXhr(xhr))
      })
    })
  }

  getPdf() {
    this.loadPdf()
      .then(data => {
        if (data.url) return this.setState({ previewUrl: data.url, bookmarkData: this.sanitizeBookmarkHash(data.bookmark_hash) })
        if (data.generating) return setTimeout(() => { this.getPdf() }, 2000)
      })
  }

  sanitizeBookmarkHash(bookmarkHash) {
    var sanitizedHash = {}

    for (let key in bookmarkHash)
      if (bookmarkHash.hasOwnProperty(key))
        sanitizedHash[decodeEntities(key)] = bookmarkHash[key]

    return sanitizedHash
  }

  open() {
    this.loadPackage()
      .then(data => {
        this.setState({thePackage: data})
        $(this.refs.modal).modal("show")})
  }

  listenModalClose() {
    $(`.${containerClassName}`).on("hidden.bs.modal", function () {
      this.props.unmount()
    }.bind(this));
  }

  loadPackage() {
    const url = `/packages/templates/${this.props.hashedId}`

    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        success: data => resolve(data),
        error: xhr => reject(errorsFromXhr(xhr))
      })
    })
  }

  mapSectionsWithPage() {
    const { thePackage, bookmarkData } = this.state
    const requirements = thePackage.package_requirements
    let bookmarkDataCopy = bookmarkData
    let packageCopy = thePackage

    for (let i = 0; i < requirements.length; i++) {
      let requirement = requirements[i]
      let page = bookmarkDataCopy[requirement.title].shift() // grabs first available page matching title
      let packageTemplateDocuments = requirement.package_template_documents

      // set page number of the requirement divider
      packageCopy.package_requirements[i]["pageNumber"] = page

      for (let j = 0; j < packageTemplateDocuments.length; j++) {
        let packageTemplateDocument = packageTemplateDocuments[j]
        let pages = bookmarkDataCopy[packageTemplateDocument.resource_name]
        let page = pages ? pages.shift() : null // grabs first available page matching doc name

        // set page number of document/form
        packageCopy.package_requirements[i].package_template_documents[j]["pageNumber"] = page
      }
    }

    this.setState({ thePackage: packageCopy, mappedPages: true })
  }

  handleToggleTab(activeTab) {
    this.setState({ activeTab: activeTab })
  }

  jumpToPage(e, pageNumber) {
    e.stopPropagation()

    if (!pageNumber) return null

    this.setState({ pdfPageToOpen: pageNumber, activeTab: "pdf" })
  }

  setLoading(bool) {
    this.setState({loading: bool})
  }

  renderLoading() {
    return <div className="loading-dots"></div>
  }

  renderTabs() {
    const { activeTab } = this.state
    return <div className="row tabs-container">
      <div
        className={`col-xs-4 preview-tab ${activeTab == "requirements" ? "active" : ""}`}
        onClick={() => this.handleToggleTab("requirements")}>Requirements</div>
      <div
        className={`col-xs-4 tab-fees preview-tab ${activeTab == "fees" ? "active" : ""}`}
        onClick={() => this.handleToggleTab("fees")}>Fees</div>
      <div
        className={`col-xs-4 preview-tab ${activeTab == "pdf" ? "active" : ""}`}
        onClick={() => this.handleToggleTab("pdf")}>PDF View</div>
      </div>
  }

  renderRequirementsList() {
    let list = []

    for (let i = 0; i < this.state.thePackage.package_requirements.length; i++) {
      const requirement = this.state.thePackage.package_requirements[i]

      list.push(<Requirement
        key={`requirement-${requirement.id}`}
        requirement={requirement}
        sortOrder={i+1}
        jumpToPage={this.jumpToPage} />)
    }

    return list
  }

  renderPdfTab() {
    const { pdfPageToOpen, previewUrl } = this.state

    let page = pdfPageToOpen || 1
    const pdfUrl = previewUrl + `#page=${page}`

    return <div className="tab-content tab-content-for-pdf-view">
      <div className="tab-header-text">
        This is a preview of the application package.
      </div>
      <div className="preview-content-container">
        <PdfView previewUrl={pdfUrl} />
      </div>
    </div>
  }

  renderFeesTab() {
    return <div className="tab-content tab-content-for-fees-view">
      <div className="tab-header-text">
        This is an overview of fees associated with applying to this building. Building and management fees are followed by BoardPackager processing fees.
      </div>
      <div className="preview-content-container">
        <Fees
              fees={this.props.fees}
              free_initialization={this.props.packageTemplate.default_skip_initiation_fee}
              orgHandlesBPFee={this.props.orgHandlesBPFee}
              hideProcessingFees={this.props.hideProcessingFees}
              isRental={this.props.isRental}
              isRefinance={this.props.isRefinance}
              isTransfer={this.props.isTransfer}
              skipDsfData={this.props.skipDsfData}
              packageType={this.props.packageType}
              brokerPackageInitiationFee={this.props.brokerPackageInitiationFee} />
      </div>
    </div>
  }

  renderRequirementsTab() {
    return <div className="tab-content tab-content-for-requirements">
      <div className="tab-header-text">
        This is a preview of the application requirements. Click on a section or form name to get a closer look.
      </div>
      <div className="preview-content-container overflow-auto-container">{this.renderRequirementsList()}</div>
    </div>
  }

  renderDownloadAllBtn() {
    return <a className="btn btn-warning download-btn"
      href={`/packages/${this.props.hashedId}/download_preview`}>Download All</a>
  }

  renderFooterButton() {
    const { hashedId, packageType, packageMachineId, buildingId, activeState, userSignedIn, userIsManagingAgentOfBuildingOrg, userRoleRestrictedFromApplying } = this.props
    const { previewUrl, thePackage: { label } } = this.state

    switch(activeState) {
      case 'apply_online':
        if (userIsManagingAgentOfBuildingOrg)
          return this.renderDownloadAllBtn()

        return <ApplyButton
          buttonText="Apply Online!"
          hashedId={ hashedId }
          packageType={ packageType }
          packageMachineId={ packageMachineId }
          buildingId={ buildingId }
          userRoleRestrictedFromApplying={ userRoleRestrictedFromApplying }
          userSignedIn={userSignedIn}
          unmountOtherModal= { this.props.unmount } />
      case 'preview':
        return this.renderDownloadAllBtn()
      default:
        null
    }
  }

  renderHeader() {
    const { thePackage } = this.state

    return <div className="modal-header">
      <div className="border-bottom-grey">
        <span>
          <span className="text-bopa">Preview:</span> {thePackage.label}
        </span>
        <span className="pull-right total-requirements">
          <span className="text-bopa">Total Requirements: </span> {thePackage.package_requirements.length}
        </span>
        <button className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {this.renderTabs()}
    </div>
  }

  renderBody() {
    return <div className="modal-body">
      { this.state.activeTab == "requirements" ? this.renderRequirementsTab() : null }
      { this.state.activeTab == "pdf" ? this.renderPdfTab() : null }
      { this.state.activeTab == "fees" ? this.renderFeesTab() : null }
    </div>
  }

  renderFooter() {
    return <div className="modal-footer">
      { this.renderFooterButton() }
    </div>
  }

  renderContent() {
    if (this.state.loading) return this.renderLoading()

    return <div className="modal-content">
      {this.renderHeader()}
      {this.renderBody()}
      {this.renderFooter()}
    </div>
  }

  render() {
    return <div className={`${containerClassName} modal`} ref="modal" role="dialog">
      <div className="modal-dialog modal-lg" role="document">{this.renderContent()}</div>
    </div>
  }
}

Preview.propTypes = {
  packageTemplate: PropTypes.object.isRequired,
  activeState: PropTypes.string.isRequired,
  buildingId: PropTypes.number,
  hashedId: PropTypes.string.isRequired,
  fees: PropTypes.array,
  packageMachineId: PropTypes.number,
  packageType: PropTypes.string,
  unmount: PropTypes.func.isRequired,
  userSignedIn: PropTypes.bool.isRequired,
  userIsManagingAgentOfBuildingOrg: PropTypes.bool.isRequired,
  userRoleRestrictedFromApplying: PropTypes.bool,
  skipDsfData: PropTypes.object
}

export default Preview
