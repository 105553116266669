import React, { useState } from 'react'
import { isEmpty, isFinite } from 'lodash-es'
import ViaffinityDisclosureContent from '../../ViaffinityDisclosureContent'
import AboutYouSidebarDetail from './AboutYouSidebarDetail'
import AboutYouSidebarHeadline from './AboutYouSidebarHeadline'
import clsx from 'clsx'

export default function AboutYouSidebar({ quote, isPolicy, insuranceType, sidebarDetails, startingQuote }) {
  const {
    premium,
    writingCompany,
    liabilityLimit,
    homeAndContentsDeductible,
    contentsAmount,
    additionsAndAlterationsAmount,
  } = isEmpty(quote) && startingQuote ? startingQuote : quote
  const parsedPremium = parseInt(premium)
  const monthlyAmount = (isFinite(parsedPremium) ? parsedPremium : 0) / 12
  const [mobileExpanded, setMobileExpanded] = useState(true)
  const onToggleExpand = () => setMobileExpanded((mobileExpanded) => !mobileExpanded)

  return (
    <>
      <div className="sidebar-desktop">
        <div className="about-you-sidebar-desktop dome-bg-white dome-dark-shadow dome-rounded-border">
          <div className="sidebar-headline">
            <AboutYouSidebarHeadline
              monthlyAmount={monthlyAmount}
              writingCompany={writingCompany}
              type={insuranceType}
            />
          </div>
          <div className="sage-line my-18"></div>
          <AboutYouSidebarDetail
            isPolicy={isPolicy}
            liabilityLimit={liabilityLimit}
            homeAndContentsDeductible={homeAndContentsDeductible}
            contentsAmount={contentsAmount}
            additionsAndAlterationsAmount={additionsAndAlterationsAmount}
            sidebarDetails={sidebarDetails}
          />
        </div>

        <div className="viaffinity-disclosure-desktop dome-bg-white dome-dark-shadow dome-rounded-border">
          <div className="dome-p3" style={{ marginBottom: '24px' }}>
            * Estimate based on property’s requirements, insurer’s recommendations, or expected values and is not final.
          </div>
          <ViaffinityDisclosureContent />
        </div>
      </div>

      <div className="sidebar-mobile">
        <div className="about-you-sidebar-mobile">
          <div className="sidebar-headline">
            <AboutYouSidebarHeadline
              monthlyAmount={monthlyAmount}
              writingCompany={writingCompany}
              type={insuranceType}
              mobileExpanded={mobileExpanded}
              onToggleExpand={onToggleExpand}
            />
          </div>
          <div className={clsx('sidebar-content', mobileExpanded ? '' : 'collapsed')}>
            <div className="dome-bg-white dome-dark-shadow dome-rounded-border">
              <AboutYouSidebarDetail
                isPolicy={isPolicy}
                liabilityLimit={liabilityLimit}
                homeAndContentsDeductible={homeAndContentsDeductible}
                contentsAmount={contentsAmount}
                additionsAndAlterationsAmount={additionsAndAlterationsAmount}
                sidebarDetails={sidebarDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
