import React from 'react'
import { createSlice } from '@reduxjs/toolkit'

import { useDispatch, useSelector } from 'react-redux'
import { merge } from 'lodash'

import { useGenerateComponent } from '.'
import getToolbar, { prepareComponents, generateEmpty } from 'components/form_creator/tools/highlight'
import { addCSRF } from 'lib/utilities'
import axios from 'axios'

const highlightIsOnPage = (highlight, page_id) => highlight.position.page == page_id

// The highlight state
const initialState = {
  lookups: {},
  by_page: {},
  next_id: -1
}

function addHighlightToPage(by_page, highlight) {
  const { position } = highlight
  by_page[position.page] ||= []
  by_page[position.page].push(highlight.id)
}

function removeHighlightFromPage(by_page, highlight) {
  if (!highlight)
    return

  const { position } = highlight
  const { page: page_id } = position

  let page = by_page[page_id] || []
  page = page.filter(id => id != highlight.id)

  if (page.length > 0)
    by_page[page_id] = page
  else
    delete by_page[page_id]
}

const Store = createSlice({
  name: "highlight",
  initialState,
  reducers: {
    setHighlights(state, { payload: highlights }) {
      const lookups = { ... (highlights || {}) }

      const by_page = {}
      for (let id in highlights)
        addHighlightToPage(by_page, highlights[id])

      state.lookups = lookups
      state.by_page = by_page
    },

    mergeHighlights(state, { payload: highlights}) {
      const lookups = { ...state.lookups, ... (highlights || {}) }

      const by_page = { ... state.by_page }
      for (let id in highlights)
        addHighlightToPage(by_page, highlights[id])

      state.lookups = lookups
      state.by_page = by_page
    },

    setHighlight(state, { payload: highlight }) {
      const lookups= { ... state.lookups }
      const by_page = { ... state.by_page }

      const id = highlight.id || state.next_id
      removeHighlightFromPage(by_page, lookups[id])

      lookups[id] = { ...highlight, id }
      addHighlightToPage(by_page, lookups[id])

      if (id == state.next_id)
        state.next_id -= 1

      state.lookups = lookups
      state.by_page = by_page
    },

    removeHighlight(state, { payload: id}) {
      const lookups= { ... state.lookups }
      const by_page = { ... state.by_page }

      removeHighlightFromPage(by_page, lookups[id])
      delete lookups[id]

      state.lookups = lookups
      state.by_page = by_page
    }
  }
})

const { setHighlight, removeHighlight } =  Store.actions
export const { setHighlights, mergeHighlights } = Store.actions
export const reducer = Store.reducer


export function useGenerator({keepDrawing} = {}) {
  const dispatch = useDispatch()

  return rect => {
    const highlight = merge(generateEmpty(), { position: rect })

    if (keepDrawing)
      highlight.newlyAdded = true

    dispatch(setHighlight(highlight))
  }
}

export function useComponents(tools, page_id) {
  const highlightIds = useSelector(({highlights}) => highlights.by_page[page_id] || [])
  const highlights = useSelector(({highlights}) => highlightIds.map(id => highlights.lookups[id]))

  //const highlights = useSelector(state => Object.values(state.highlights.lookups).filter(h => highlightIsOnPage(h, page_id)))
  const selectedComponents = [] //useSelector(state => state.general.selectedComponents)

  const generateComponent = useGenerateComponent(tools, selectedComponents)
  return prepareComponents('highlight', highlights).map(generateComponent)
}

function useApi() {
  // Generate the API
  const dispatch = useDispatch()
  const highlights = useSelector(state => state.highlights.lookups)
  const instance_id = useSelector(({ global: state }) => state.instance_id)

  const update = (id, componentData) => {
    dispatch(setHighlight({ id, ...componentData }))
    saveHighlight({ instance_id, id, componentData })
  }

  const create = (componentData, id) => {
    update(id, merge(generateEmpty(), componentData))
  }

  const read = id => highlights[id]

  const destroy = id => {
    dispatch(removeHighlight(id))
    destroyHighlight({ instance_id, id })
  }

  return { create, read, update, destroy }
}

export function useTool() {
  const api = useApi()
  return getToolbar({name: 'highlight', api })
}

function saveHighlight({ instance_id, id, componentData }) {
  const url = `/forms/v3/instance/${instance_id}/highlight`
  const { position, options } = componentData
  const data = addCSRF({ highlight: { instance_id, id, position, options } })

  return new Promise((res, rej) => {
    const success = ({ highlight }) => res([highlight])

    const error = xhr => {
      try {
        const err = JSON.parse(xhr.responseText)
        rej(err.error)
      } catch (e) {
        rej("There was a problem accessing the server")
      }
    }

    axios.post(url, data).then(res => success(res)).catch(xhr => error(xhr))
  })
}

function destroyHighlight({ instance_id, id }) {
  const url = `/forms/v3/instance/${instance_id}/highlight`
  const data = addCSRF({ highlight: { instance_id, id } })

  return new Promise((res, rej) => {
    const success = ({ highlight }) => res([highlight])

    const error = xhr => {
      try {
        const err = JSON.parse(xhr.responseText)
        rej(err.error)
      } catch (e) {
        rej("There was a problem accessing the server")
      }
    }

    axios.delete(url, data).then(res => success(res)).catch(xhr => error(xhr))
  })
}
