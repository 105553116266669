import { startsWith, endsWith, isPlainObject } from 'lodash'
import { toAbsolutePath, metadataByPath } from "../form"

function isStringValue(value) {
  if (startsWith(value, "'") && endsWith(value, "'"))
    return true

  if (startsWith(value, '"') && endsWith(value, '"'))
    return true

  return false
}

export function analyzeValue(value, request) {
  if (typeof value != "string")
    return value

  if (isStringValue(value))
    return value.slice(1, -1)

  const path = toAbsolutePath(value, request.relativeTo)
  return request.pathMap[path]
}

export const translateValue = analyzeValue
