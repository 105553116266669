import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { startAppListening } from 'components/global/listener'
import {
  createQuoteApi,
  createQuoteRequestApi,
  getQuoteRequestStatusApi,
  getBuildingRequirementsApi,
  getSignatureApi,
  createPolicyRequestApi,
  getQuoteStatusApi,
} from './apis'
import { checkUserSignedInApi } from 'components/global/apis'

import { isEmpty, isEqual, pick } from 'lodash-es'
import { differenceInMilliseconds, formatISO } from 'date-fns'

export const INSURANCE_QUOTE_STEPS = {
  property_page: 'insurance_quote-property_page',
  policy_page: 'insurance_quote-policy_page',
  about_you_page: 'insurance_quote-about_you_page',
  finalize_page: 'insurance_quote-finalize_page',
  error_page: 'insurance_quote-error_page',
  quote_sent_page: 'insurance_quote-quote_sent_page',
}

const FORM_STEPS = {
  [INSURANCE_QUOTE_STEPS.property_page]: 'property',
  [INSURANCE_QUOTE_STEPS.policy_page]: 'policy',
  [INSURANCE_QUOTE_STEPS.about_you_page]: 'about_you',
  [INSURANCE_QUOTE_STEPS.finalize_page]: 'finalize',
}

const GENERIC_MESSAGE =
  'There was an error in processing. Please try again. If the problem persists, please contact insurance-support@domecile.com.'
const WRONG_ADDRESS_ERROR = {
  code: 'E007',
  desc: 'No hit on RAND check.',
}

const centConversionFields = [
  'personal_liability',
  'deductible_amount',
  'additions_alterations_amount',
  'content_coverage',
]

const formFields = {
  [INSURANCE_QUOTE_STEPS.property_page]: [],
  [INSURANCE_QUOTE_STEPS.policy_page]: [
    'step',
    'building_id',
    'package_id',
    'building_structure_id',
    'building_unit',
    'square_footage',
    'occupancy',
    'start_at',
    'quote_expires_at',
    'personal_liability',
    'deductible_amount',
    'additions_alterations_amount',
    'content_coverage',
  ],
  [INSURANCE_QUOTE_STEPS.about_you_page]: [
    'step',
    'building_id',
    'package_id',
    'building_structure_id',
    'building_unit',
    'square_footage',
    'occupancy',
    'start_at',
    'quote_expires_at',
    'personal_liability',
    'deductible_amount',
    'additions_alterations_amount',
    'content_coverage',
    'first_name',
    'middle_name',
    'last_name',
    'email',
    'phone_number',
    'dob',
    'insured_type',
    'occupation_type',
    'occupation_detail',
    'number_of_mortgages',
    'prior_carrier_name',
    'is_billing_address_same_as_location',
    'billing_address_line1',
    'billing_address_line2',
    'billing_city',
    'billing_state',
    'billing_zip',
    'billing_county',
  ],
  [INSURANCE_QUOTE_STEPS.finalize_page]: [
    'step',
    'building_id',
    'package_id',
    'building_structure_id',
    'building_unit',
    'square_footage',
    'occupancy',
    'start_at',
    'quote_expires_at',
    'personal_liability',
    'deductible_amount',
    'additions_alterations_amount',
    'content_coverage',
    'first_name',
    'middle_name',
    'last_name',
    'email',
    'phone_number',
    'insured_type',
    'occupation_type',
    'occupation_detail',
    'number_of_mortgages',
    'prior_carrier_name',
    'is_billing_address_same_as_location',
    'billing_address_line1',
    'billing_address_line2',
    'billing_city',
    'billing_state',
    'billing_zip',
    'billing_county',
  ],
}

const prepareFormToSubmit = (formData, fields) => {
  const data = pick(formData, fields)

  for (const key in data) {
    if (centConversionFields.includes(key) && data[key]) {
      data[key] = data[key] * 100
    }
  }

  return data
}

const quoteRequestStatuses = {
  'not-initiated': 'not-initiated',
  'initiated': 'initiated',
  'awaiting-response': 'awaiting-response',
  'completed': 'completed',
  'failed': 'failed',
}

const initialState = {
  insuranceConstants: {},
  currentUser: {},
  building: null,
  package_id: null,
  insuranceType: 'homeowners',
  requirements: {},
  requirementsLoading: false,
  minimums: {},
  quote: {},
  quoteRequests: [],
  lastRequestStatus: quoteRequestStatuses['not-initiated'],
  fullScreenLoading: {
    visible: false,
    message: '',
  },
  step: INSURANCE_QUOTE_STEPS.property_page,
  formData: {},
  signatureFormData: {},
  savedSignature: {},
  closeConfirmModalVisibility: false,
  legalDisclaimerModalVisibility: false,
  serverErrorModalVisibility: false,
  serverError: false,
  errorDescription: null,
  startingQuote: null,
  userSignedIn: false,
}

export const createQuote = createAsyncThunk('insuranceQuoteInterstitial/createQuote', async ({ data }) => {
  const response = await createQuoteApi(data)
  return response.data
})

export const createQuoteRequest = createAsyncThunk(
  'insuranceQuoteInterstitial/createQuoteRequest',
  async ({ quote_id, data }) => {
    const response = await createQuoteRequestApi(quote_id, data)
    return response.data
  }
)

export const getQuoteRequestStatus = createAsyncThunk(
  'insuranceQuoteInterstitial/getQuoteRequestStatus',
  async (requestId) => {
    const response = await getQuoteRequestStatusApi(requestId)
    return response.data
  }
)

export const getQuoteStatus = createAsyncThunk('insuranceQuoteInterstitial/getQuoteStatus', async (quoteId) => {
  const response = await getQuoteStatusApi(quoteId)
  return response.data
})

export const getSignature = createAsyncThunk('insuranceQuoteInterstitial/getSignature', async () => {
  const response = await getSignatureApi()
  return response.data
})

export const requestIssuePolicy = createAsyncThunk(
  'insuranceQuoteInterstitial/requestIssuePolicy',
  async ({ quote_id, data }, thunkAPI) => {
    try {
      const response = await createPolicyRequestApi(quote_id, { quote: data })
      return response.data
    } catch (error) {
      if (error?.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else {
        throw error
      }
    }
  }
)

export const getBuildingRequirements = createAsyncThunk(
  'insuranceQuoteInterstitial/getBuildingRequirements',
  async (buildingId) => {
    const response = await getBuildingRequirementsApi(buildingId)
    return response.data
  }
)

export const checkUserSignedIn = createAsyncThunk('insuranceQuoteInterstitial/checkUserSignedIn', async () => {
  const response = await checkUserSignedInApi()
  return response.data
})

export const checkSignedInAndGetSignature = createAsyncThunk(
  'insuranceQuoteInterstitial/checkSignedInAndGetSignature',
  async (_, { dispatch, getState }) => {
    const result = await dispatch(checkUserSignedIn())
    if (checkUserSignedIn.fulfilled.match(result) && getState().insuranceQuoteInterstitial.userSignedIn) {
      await dispatch(getSignature(result.payload))
    }
  }
)

const insuranceQuoteInterstitialSlice = createSlice({
  name: 'insuranceQuoteInterstitial',
  initialState,
  reducers: {
    initiateQuote: (state, action) => {
      state.quote = {}
      state.quoteRequests = []
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
    setPackageId: (state, action) => {
      state.package_id = action.payload
    },
    setFullLoading: (state, action) => {
      state.fullScreenLoading = action.payload
    },
    resetStateWithProperty: (state, action) => ({
      ...initialState,
      insuranceConstants: state.insuranceConstants,
      building: action.payload,
      currentUser: state.currentUser,
      startingQuote: state.startingQuote,
    }),
    setFormData: (state, action) => {
      state.formData = action.payload.data
      state.formData.step = action.payload.step
    },
    setFormConstants: (state, action) => {
      state.insuranceConstants = action.payload
    },
    storeQuoteInfo: (state, action) => {
      Object.assign(state.quote, action.payload)
    },
    setSignatureFormData: (state, action) => {
      state.signatureFormData = action.payload
    },
    setServerError: (state, action) => {
      state.serverError = action.payload
    },
    setServerErrorModalVisibility: (state, action) => {
      state.serverErrorModalVisibility = action.payload
    },
    setErrorDescription: (state, action) => {
      state.errorDescription = action.payload
    },
    setLegalDisclaimerModalVisibility: (state, action) => {
      state.legalDisclaimerModalVisibility = action.payload
    },
    setCloseConfirmModalVisibility: (state, action) => {
      state.closeConfirmModalVisibility = action.payload
    },
    setInsuranceType: (state, action) => {
      state.insuranceType = action.payload
    },
    setStartingQuote: (state, action) => {
      state.startingQuote = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createQuote.fulfilled, (state, action) => {
      state.quote['id'] = action.payload.quote_id
      state.lastRequestStatus = quoteRequestStatuses.initiated
      state.quoteRequests.push({ request_id: action.payload.request_id, requested_at: formatISO(new Date()) })
    })
    builder.addCase(createQuoteRequest.rejected, (state, action) => {
      state.lastRequestStatus = quoteRequestStatuses.failed
      state.quoteRequests.push({ request_id: null })
    })
    builder.addCase(createQuoteRequest.fulfilled, (state, action) => {
      state.lastRequestStatus = quoteRequestStatuses.initiated
      state.quoteRequests.push({ request_id: action.payload.request_id, requested_at: formatISO(new Date()) })
    })
    builder.addCase(getQuoteRequestStatus.fulfilled, (state, action) => {
      state.lastRequestStatus = action.payload.status

      const quoteRequestIndex = state.quoteRequests.findIndex(
        (quoteRequest) => quoteRequest.request_id == action.payload.request_id
      )
      if (quoteRequestIndex !== -1) {
        state.quoteRequests[quoteRequestIndex] = {
          ...action.payload,
          requested_at: state.quoteRequests[quoteRequestIndex].requested_at,
        }
      }
    })
    builder.addCase(checkUserSignedIn.fulfilled, (state, action) => {
      state.userSignedIn = action.payload.signed_in
    })
    builder.addCase(getSignature.fulfilled, (state, action) => {
      state.savedSignature = action.payload
    })
    builder.addCase(getBuildingRequirements.pending, (state, action) => {
      state.requirementsLoading = true
    })
    builder.addCase(getBuildingRequirements.fulfilled, (state, action) => {
      state.requirements = action.payload.requirements
      state.minimums = action.payload.minimums
      state.requirementsLoading = false
    })
    builder.addCase(requestIssuePolicy.pending, (state, action) => {
      state.fullScreenLoading = { visible: true, message: 'Loading...' }
    })
    builder.addCase(requestIssuePolicy.rejected, (state, { payload }) => {
      if (payload?.error) {
        state.errorDescription = payload?.error.join(',')
      }
    })
    builder.addCase(getQuoteStatus.fulfilled, (state, action) => {
      const { viafinity_id, status } = action.payload
      state.quote.quoteStatus = status
    })
  },
})

export const selectInsuranceQuoteInterstitialState = (state) => state.insuranceQuoteInterstitial

export const selectCurrentBuildingStructure = createSelector(selectInsuranceQuoteInterstitialState, state => {
  const { building, formData } = state
  
  return building?.building_structures?.find(
    (building_structure) => building_structure.id === parseInt(formData?.building_structure_id)
  )
})

export const {
  initiateQuote,
  setInsuranceType,
  setStep,
  setFullLoading,
  setLegalDisclaimerModalVisibility,
  resetStateWithProperty,
  setPackageId,
  setFormData,
  setFormConstants,
  storeQuoteInfo,
  setSignatureFormData,
  setCloseConfirmModalVisibility,
  setServerErrorModalVisibility,
  setServerError,
  setErrorDescription,
  setCurrentUser,
  setStartingQuote,
} = insuranceQuoteInterstitialSlice.actions

export default insuranceQuoteInterstitialSlice.reducer

// interval get quote request status
const MAX_TIMEOUT = 1000 * 60
const intervalGetQuoteRequestStatus = createAction('insuranceQuoteInterstitial/intervalGetQuoteRequestStatus')
startAppListening({
  actionCreator: intervalGetQuoteRequestStatus,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners()

    const { nextStep, rollbackStep } = action.payload
    const { getState, dispatch } = listenerApi

    const interval = setInterval(() => {
      const interstitialState = getState().insuranceQuoteInterstitial
      const { lastRequestStatus, quote, quoteRequests } = interstitialState

      if (isEmpty(quote) || isEmpty(quoteRequests)) {
        clearInterval(interval)
        return
      }

      const lastQuoteRequestIndex = quoteRequests.length - 1
      const lastQuoteRequest = quoteRequests[lastQuoteRequestIndex]
      if (lastRequestStatus !== 'completed') {
        const duration = differenceInMilliseconds(new Date(), lastQuoteRequest.requested_at)

        if (duration > MAX_TIMEOUT) {
          if (rollbackStep) dispatch(setStep(rollbackStep))
          dispatch(setServerError(true))
          dispatch(setFullLoading({ visible: false }))
          clearInterval(interval)

          return
        }

        dispatch(getQuoteRequestStatus(lastQuoteRequest.request_id))
      } else {
        const {
          quote: {
            status: quoteStatus,
            insurance_type: insuranceType,
            building_id: buildingId,
            consent_document_url: consentDocumentUrl,
          },
          response: { errorDetails, detail, status, premium, writingCompany, quoteNumber, legalDisclaimer },
          request: {
            homeAndContentsDeductible,
            liabilityLimit,
            policyEffectiveDate,
            policyExpirationDate,
            contentsAmount,
            additionsAndAlterationsAmount,
          },
        } = lastQuoteRequest

        dispatch(setErrorDescription(null))

        if (!premium || !isEmpty(errorDetails || {}) || status === 500) {
          // error
          if (rollbackStep) {
            dispatch(setStep(rollbackStep))
          }
          if (status === 500) {
            dispatch(setServerError(true))
          }

          if (status === 400 && !isEmpty(errorDetails.desc)) {
            // TODO: Send back to user quote request uuid
            const errorMessage = isEqual(errorDetails, WRONG_ADDRESS_ERROR) ? GENERIC_MESSAGE : errorDetails.desc

            dispatch(setErrorDescription(errorMessage))
          }
        } else {
          // success, has premium
          dispatch(
            storeQuoteInfo({
              premium,
              writingCompany,
              liabilityLimit,
              homeAndContentsDeductible,
              quoteNumber,
              legalDisclaimer,
              policyEffectiveDate,
              policyExpirationDate,
              contentsAmount,
              additionsAndAlterationsAmount,
              quoteStatus,
              insuranceType,
              buildingId,
              consentDocumentUrl,
            })
          )
          if (nextStep) {
            dispatch(setStep(nextStep))
          }
        }

        dispatch(setFullLoading({ visible: false }))
        clearInterval(interval)
      }
    }, 2000)
  },
})

// initiate quote flow
startAppListening({
  actionCreator: initiateQuote,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners()

    const { getState, dispatch, delay, condition } = listenerApi
    const { building, package_id, insuranceType, formData } = getState().insuranceQuoteInterstitial
    const { rollbackStep, nextStep } = action.payload

    const step = formData.step === 'policy' ? 'policy' : 'property'
    let data = {
      step: step,
      building_id: building.id,
      package_id: package_id,
      insurance_type: insuranceType,
    }

    if (formData.step === 'policy') {
      data = { ...prepareFormToSubmit(formData, formFields[INSURANCE_QUOTE_STEPS.policy_page]), ...data }
    }

    dispatch(setFullLoading({ visible: true, message: 'Gathering your estimate.' }))
    dispatch(
      createQuote({
        data: data,
      })
    )

    if (await condition(createQuote.fulfilled.match, 10000)) {
      delay(1)
      dispatch(
        intervalGetQuoteRequestStatus({
          rollbackStep: rollbackStep,
          nextStep: nextStep,
        })
      )

      return
    }

    // error, cannot create quote
    dispatch(setStep(rollbackStep || INSURANCE_QUOTE_STEPS.property_page))
  },
})

// initiate quote request flow
export const initiateQuoteRequest = createAction('insuranceQuoteInterstitial/initiateQuoteRequest')
startAppListening({
  actionCreator: initiateQuoteRequest,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners()

    const { getState, dispatch, delay, condition } = listenerApi
    const { building, quote, formData } = getState().insuranceQuoteInterstitial
    const { currentStep, nextStep } = action.payload

    dispatch(setFullLoading({ visible: true, message: 'Gathering your estimate.' }))
    dispatch(
      createQuoteRequest({
        quote_id: quote.id,
        data: {
          ...prepareFormToSubmit(formData, formFields[currentStep]),
          step: FORM_STEPS[currentStep],
          building_id: building.id,
        },
      })
    )

    if (await condition(createQuoteRequest.fulfilled.match, 10000)) {
      delay(1)
      dispatch(
        intervalGetQuoteRequestStatus({
          nextStep: nextStep,
        })
      )

      return
    }

    // error, cannot create quote request
    dispatch(setStep(currentStep))
  },
})

const intervalGetQuoteStatus = createAction('insuranceQuoteInterstitial/intervalGetQuoteStatus')
startAppListening({
  actionCreator: intervalGetQuoteStatus,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners()

    const { nextStep, rollbackStep } = action.payload
    const { getState, dispatch } = listenerApi

    const interval = setInterval(() => {
      const interstitialState = getState().insuranceQuoteInterstitial
      const { quote } = interstitialState

      if (isEmpty(quote)) {
        clearInterval(interval)
        return
      }

      if (quote.quoteStatus === 'QUOTED') {
        dispatch(getQuoteStatus(quote.id))
      } else {
        if (quote.quoteStatus !== 'REQUESTED_ISSUANCE') {
          dispatch(setErrorDescription('Something wrong happened when the quote was being issued.'))
        } else if (nextStep) {
          dispatch(setStep(nextStep))
        }

        dispatch(setFullLoading({ visible: false }))
        clearInterval(interval)
      }
    }, 2000)
  },
})

startAppListening({
  actionCreator: requestIssuePolicy.fulfilled,
  effect: (_action, listenerApi) => {
    listenerApi.cancelActiveListeners()
    listenerApi.dispatch(
      intervalGetQuoteStatus({
        nextStep: INSURANCE_QUOTE_STEPS.quote_sent_page,
      })
    )
  },
})
