import $ from 'jquery'
import { compact, isEmpty, omitBy, last, castArray } from 'lodash'

export const csrfParam = () => $("meta[name=csrf-param]").attr("content")
export const csrfValue = () => $("meta[name=csrf-token]").attr("content")

export function addCSRF(data) {
  data[csrfParam()] = csrfValue()
  return data
}

export function allNull(item) {
  if (Array.isArray(item))
    return compact(item).length == 0

  if (typeof item == 'object')
      return compact(Object.values(item)).length == 0

  return item == null
}

export const compactObject = obj => omitBy(obj, val => !val || String(val).match(/^\s*$/))
export const compactHash = compactObject

export const asArray = obj => compact(castArray(obj))

export const removeTrailingBlanks = array => {
  const newArray = array.concat([])

  while (!isEmpty(newArray) && !last(newArray))
    newArray.splice(newArray.length - 1, 1)

  return newArray
}

export const fastHash = str => {
  str = String(str)
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
};

export function addCSRFtoFormData(data){
  data.append(csrfParam(), csrfValue())
  return data
}


function namespacedParent(name) {
  const path = name.split(".")
  path.pop()

  let parent = window
  for (let key of path) {
    if (!parent[key])
      parent[key] = {}

    parent = parent[key]
  }

  return parent
}

function gatherNameParts(name) {
  const parent = namespacedParent(name)
  const attribute = name.split(".").pop()

  return { parent, attribute }
}

export function assignIntoNamespace(name, value) {
  const { parent, attribute } = gatherNameParts(name)
  parent[attribute] = value
}

export function getFromNamespace(name) {
  const { parent, attribute } = gatherNameParts(name)
  return parent[attribute]
}

export function removeFromNamespace(name) {
  const { parent, attribute } = gatherNameParts(name)
  delete parent[attribute]
}

export async function cacheToNamespace(name, promise) {
  const { parent, attribute } = gatherNameParts(name)

  if (parent.hasOwnProperty(attribute))
    return parent[attribute]

  parent[attribute] = await promise()
  return parent[attribute]
}

function compare(a, b) {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

export function sortObjectKeys(object, order = "order") {
  const asArray = Object.entries(object)

  const sortMethod = typeof order == 'function' ? (a, b) => order(a[1], b[1]) : (a, b) => compare(a[1][order], b[1][order])

  return asArray.sort(sortMethod).map(([key]) => key)
}

export function arrayContains(array, keys) {
  if (!Array.isArray(array))
    return false

  if (!Array.isArray(keys))
    keys = [keys]

  for (let item of array)
    if (keys.includes(item))
      return true

  return false
}

function numberWithCommas(x, delimiter = ",") {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
}

export function fancyJoin(array, { delimiter = ", ", lastDelimiter = " and "} = {}) {
  if (array.length == 0)
    return ""

  if (array.length == 1)
    return array[0]

  if (array.length == 2)
    return array.join(lastDelimiter)

  const copied = array.concat([])
  const lastItem = copied.pop()

  return copied.join(delimiter) + lastDelimiter + lastItem
}

export function asMoney(amount, { currency = "$", centMarker = ".", delimiter = ","} = {}) {
  const stringAmount = String(amount)
  const dollars = stringAmount.substring(0, stringAmount.length - 2)
  const cents = stringAmount.substring(stringAmount.length - 2)

  return currency + numberWithCommas(dollars, delimiter) + centMarker + cents
}

export function centsIntegerToDollarsString(cents) {
  return (cents / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })
}

export function formatRailsTimestamp(timestamp, { month = '2-digit', day = '2-digit', year = 'numeric', hour = 'numeric', minute = 'numeric', hour12 = true } = {}) {
  if (!timestamp)
    return

  const date = new Date(timestamp)
  return date.toLocaleString('en-US', { month, day, year, hour, minute, hour12 })
}

export function dispatchEvent(eventName, detail) {
  window.dispatchEvent(new CustomEvent(eventName, { detail: detail }))
}

/**
 * Returns the index of the next non-null element in the array.
 * arr - The input array of unique characters or null elements.
 * currentIndex - The index of the current non-null element.
 * returns - The index of the next non-null element in the array, or -1 if no such element is found.
 */
export function getNextNonNullIndex(arr, currentIndex) {
  const len = arr.length
  let nextIndex = currentIndex + 1

  // Loop through the array until we have looped through the entire array once.
  let loops = 0;
  while (loops < len) {
    // If nextIndex reaches the end of the array, reset it to 0 and increment loops counter.
    if (nextIndex >= len) {
      nextIndex = 0;
      loops++;
    }
    // If we find a non-null element, return its index.
    if (arr[nextIndex] !== null) {
      return nextIndex;
    }
    // Otherwise, increment nextIndex and continue the search.
    nextIndex++;
  }

  // If we have completed one full loop through the array and haven't found a non-null element,
  // return -1 to indicate that there are no more non-null elements in the array.
  return -1;
}
