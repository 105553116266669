import { isPlainObject, isArray, isEmpty, merge, startsWith, castArray, last, fill } from "lodash"
import { arrayContains } from 'lib/utilities'

export { default as checkConditions } from './form/check_conditions'
export { default as renderTemplate } from './form/render_template'

export function hashToPathMap(hash) {
  const all_paths = Object.entries(hash).map(([key, value]) => buildIntoPathMap(key, value))

  return merge({}, ...all_paths.flat())
}

function buildIntoPathMap(prefix, object) {
  if (isPlainObject(object))
    return buildHashIntoPathMap(prefix, object)
  if (isArray(object))
    return buildArrayIntoPathMap(prefix, object)

  return {[prefix]: object}
}

function buildHashIntoPathMap(prefix, hash) {
  Object.entries(hash).map(([key, object]) => buildIntoPathMap(`${prefix}.${key}`, object))
}

function buildArrayIntoPathMap(prefix, array) {
  array.map((item, index) => buildIntoPathMap(`${prefix}[${index}]`, item))
       .concat([ { [`${prefix}[length]`]: array.length } ])
}


export function pathMapToHash(pathMap) {
  const hash = {}

  for (let path of Object.keys(pathMap)) {
    insertPathIntoHash(path.split('.'), pathMap[path], hash)
  }

  return hash
}

function insertPathIntoHash(path, value, hash) {
  if (isEmpty(path))
    return value

  const { key, index } = splitPathSegment(path.shift())

  if (index) {
    if (index == length)
      return hash

    const realIndex = parseInt(index)
    hash[key] ||= []

    for (let i = hash[key].length; i < realIndex; i++)
      hash[key][i] ||= null

    hash[key][realIndex] = insertPathIntoHash(path, value, hash[key][realIndex] || {})
  } else {
    hash[key] = insertPathIntoHash(path, value, hash[key] || {})
  }

  return hash
}

// Give a standard metadata structure and a path,
// get the field identified by the path.

export function metadataByPath(metadata, path) {
  return findMetadataByPath(metadata, path.split("."))
}

export function valueByPath(values, path) {
  return findValueByPath(values, path.split('.'))
}

export function valuesByPrefix(pathMap, prefix) {
  const filtered = pickBy(pathMap, (_, key) => startsWith(key, prefix))
  return mapKeys(filtered, (_, key) => key.substring(prefix.length + (key[prefix.length] == '.' ? 1 : 0))) 
}

export function pathsStartingWith(pathMap, path) {
  return pickBy(pathMap, (_, key) => startsWith(key, path))
}

const KEY_AND_INDEX = /(?<key>[a-zA-Z_0-9]+)(\[(?<index>-?(\d+)|(length))\])?/

export function firstPathSegment(path) {
  const segment = path.substr(0, path.indexOf('.'))
  return isEmpty(segment) ? path : segment
}

export function lastPathSegment(path) {
  return last(path.split('.'))
}

export function removeLastBrackets(path) {
  return path.replace(/\[-?\d+\]$/, '').replace(/\[length\]$/, '')
}

export function splitPathSegment(pathSegment) {
  const match = pathSegment.match(KEY_AND_INDEX)
  return match.groups
}

export function pathSegmentIndex(pathSegment) {
  const { index } = pathSegment.match(KEY_AND_INDEX)
  return index
}

export function generateRoute(path, { metadata = true } = {}) {
  const segments = path.split('.')
  const route = []

  let stop
  while (segments.length > 0) {
    stop = segments.join('.')
    route.push(metadata ? removeLastBrackets(stop) : stop)
    segments.pop()
  }

  return route
}

export function insertArrayIntoPathMap(path, array, pathMap = {}) {
  if (!Array.isArray(array))
    return pathMap

  array.forEach((value, index) => pathMap[`${path}[${index}]`] = value)
  pathMap[`${path}[length]`] = array.length

  return pathMap
}

export function extractArrayFromPathMap(pathMap, path) {
  const length = parseInt(pathMap[`${path}[length]`])
  if (!length || length == 0)
    return []

  return fill(Array(length), null).map((_, index) => pathMap[`${path}[${index}]`])
}

export function pathIsWithinPathMapArray(pathMap, path, value) {
  const length = parseInt(pathMap[`${path}[length]`])
  if (!length || length == 0)
    return false

  for (let i = 0; i < length; i++)
    if (pathMap[`${path}[${i}]`] == value)
      return true

  return false
}

function findMetadataByPath(metadata, path) {
  const {key} = splitPathSegment(path.shift())
  const field = (metadata || {})[key]

  if (!field)
    return null

  if (path.length == 0)
    return field

  return findMetadataByPath( field.fields, path)
}

function findValueByPath(values, path) {
  if (path.length == 0 || !values)
    return values

  if (typeof values != 'object')
    return

  const {key, index} = splitPathSegment(path.shift())
  let value = values[key]

  if (index == 'length')
    return null

  if (!index)
    return findValueByPath(value, path)

  if (Array.isArray(value))
    return findValueByPath(value[parseInt(index)], path)
    
  return null
}

export function possibleIndexes(values, id) {
  const field_path = id.replace(/\[-?\d+\]$/, '')
  const field_id = lastPathSegment(field_path)
  const indexes = new Set()

  for (let path in values) {
    if (!startsWith(path, field_path))
      continue
    
    const segment = firstPathSegment(path.replace(field_path, field_id))
    const { index } = splitPathSegment(segment)
    if (index)
      indexes.add(index)
  }

  return Array.from(indexes)
}

export function unauthorizedDuplicates(id, field, all_values) {
  if (!field.multiple || isEmpty(field.unique_on))
    return []

  const field_path = id.replace(/\[-?\d+\]$/, '')
  const indexes = possibleIndexes(all_values, field_path)
  if (indexes.length == 0)
    return []

  return field.unique_on.map(castArray).filter(uniqueness => {
    // Values per Index
    const valueLists = indexes.map(index => uniqueness.map(key => all_values[`${field_path}[${index}].${key}`]))
    // Remove indexes that may be empty
    const filtered = valueLists.map(items => items.filter(val => !isEmpty(val)))
    const valueTokens = new Set()
    for (let item of filtered)
      valueTokens.add(item.join("-|-"))
    
    return valueTokens.size != indexes.length
  })
}

export function checkPermissions(permissions, { read = [], write = [] } = {}) {
  if (arrayContains(permissions.read, read))
    return true

  if (arrayContains(permissions.write, write))
    return true

  return false
}

function countFirstCharacter(path, char) {
  for (let index = 0; index < path.length; index++)
    if (path[index] != char)
      return index

  return path.length
}

export function toAbsolutePath(path, relativeTo = null) {
  if (path[0] != "." || !relativeTo)
    return path 

  const bumpsUp = countFirstCharacter(path, ".")

  relativeTo = relativeTo.split('.')
  relativeTo = bumpsUp == 0 ? relativeTo : relativeTo.slice(0, bumpsUp - 1)

  if (relativeTo.length == 0)
    return path.slice(bumpsUp)

  return relativeTo.join(".") + "." + path.slice(bumpsUp)
}